import React, { useContext, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const statsData = [
    { regionEn: "Central Region", regionFr: "Région Centre" },
    { regionEn: "Coast Region", regionFr: "Région Côte" },
    { regionEn: "Nyanza Region", regionFr: "Région Nyanza" },
    { regionEn: "Western Region", regionFr: "Région Ouest" },
    { regionEn: "Rift Valley North", regionFr: "Région RV Nord" },
    { regionEn: "Rift Valley South", regionFr: "Région RV Sud" },
    { regionEn: "Nairobi Region", regionFr: "Région Nairobi" },
    { regionEn: "South Eastern Region", regionFr: "Région Sud Est" },
    { regionEn: "Primary/Private", regionFr: "Primaire/Privée" },
    { regionEn: "Teacher Training College", regionFr: "TTC" },
    { regionEn: "TVET/Polytechnic", regionFr: "TTI/TVET/Polytec" },
    { regionEn: "University", regionFr: "Université" },
  ];

const SchoolsByRegion = () => {
    const { language } = useContext(LanguageContext);
    const [selectedRegion, setSelectedRegion] = useState(null);

    const schoolData = {
        "Central Region": [
          { name: "Alliance Girls' High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Githiga High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Limuru Girls' School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Loreto High School Limuru", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "M.P Shah Chania High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Mary Leakey Girls' High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Our Lady of Mount Carmel Maryhill Girls'", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "St Ann-Lioki", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Juja St Peter's School", county: "Kiambu", type: translations[language].schoolTypeTwo },
          { name: "Gathirimu Girls' High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Mang'u High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Thika High School for the Blind", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Uthiru Girls High School", county: "Kiambu", type: translations[language].schoolTypeOne },
          { name: "Kabare Girls", county: "Kirinyaga", type: translations[language].schoolTypeOne },
          { name: "Kagumo Girls' Secondary School", county: "Kirinyaga", type: translations[language].schoolTypeOne },
          { name: "Kirinyaga High School", county: "Kirinyaga", type: translations[language].schoolTypeOne },
          { name: "St. Faustina Kerugoya Girls'", county: "Kirinyaga", type: translations[language].schoolTypeOne },
          { name: "Lycee de Garcons Weithaga", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "St. Charles Lwanga", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Kahuhia Girls' High School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Lycée Mumbi de Filles", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Nginda Girls' Secondary School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Njiiri School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Njumbi High School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "St. Angela Kiriaini Girls", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Murang'a High School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "OLC Mugoiri Girls' High School", county: "Murang'a", type: translations[language].schoolTypeOne },
          { name: "Karima Girls' High School", county: "Nyandarua", type: translations[language].schoolTypeOne },
          { name: "Mount Kenya Academy", county: "Nyeri", type: translations[language].schoolTypeTwo },
          { name: "Bishop Gatimu Ngandu Girls' High School", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "Kiandu Secondary School", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "Nyeri High School", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "Stella Maris Othaya Girls'", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "Mount Kenya Academy", county: "Nyeri", type: translations[language].schoolTypeThree },
          { name: "Endarasha Boys' High School", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "South Tetu Girls' High School", county: "Nyeri", type: translations[language].schoolTypeOne },
          { name: "Foresight Academy", county: "Unregistered", type: translations[language].schoolTypeTwo },
        ],
        "Coast Region": [
          { name: "Agakhan-Mombasa", county: "Mombasa", type: "N/A" },
          { name: "Allidina Visram", county: "Mombasa", type: "N/A" },
          { name: "Jaffery Academy", county: "Mombasa", type: "N/A" },
          { name: "Mama Ngina Girls", county: "Mombasa", type: "N/A" },
          { name: "AIC School - Malindi", county: "Kilifi", type: "N/A" },
          { name: "Barani Secondary School", county: "Kilifi", type: "N/A" },
          { name: "Dzitsoni Secondary School", county: "Kilifi", type: "N/A" },
          { name: "Explorer Preparatory School", county: "Kilifi", type: "N/A" },
          { name: "Majaoni Secondary School", county: "Kilifi", type: "N/A" },
          { name: "Moi Kadzonzo Girls", county: "Kilifi", type: "N/A" },
          { name: "Ribe Boys Secondary School", county: "Kilifi", type: "N/A" },
          { name: "Shariani Secondary School", county: "Kilifi", type: "N/A" },
          { name: "St. John’s Girls’ Secondary School", county: "Kilifi", type: "N/A" },
          { name: "St. Patrick's Academy", county: "Kilifi", type: "N/A" },
          { name: "Twinkle Stars Academy", county: "Kilifi", type: "N/A" },
          { name: "Babla Diani Secondary School", county: "Kwale", type: "N/A" },
          { name: "Kwale Girls", county: "Kwale", type: "N/A" },
          { name: "Kwale High School", county: "Kwale", type: "N/A" },
          { name: "Matuga Girls", county: "Kwale", type: "N/A" },
          { name: "Shimoni Secondary School", county: "Kwale", type: "N/A" },
          { name: "Waa Boys", county: "Kwale", type: "N/A" },
          { name: "Waa Girls' High School", county: "Kwale", type: "N/A" },
          { name: "Lamu Girls Secondary School", county: "Lamu", type: "N/A" },
          { name: "Mpeketoni Boys High School", county: "Lamu", type: "N/A" },
          { name: "Arya Academy - Mombasa", county: "Mombasa", type: "N/A" },
          { name: "Bondeni Junior School", county: "Mombasa", type: "N/A" },
          { name: "Braeburn Mombasa International", county: "Mombasa", type: "N/A" },
          { name: "Busy Bee School", county: "Mombasa", type: "N/A" },
          { name: "Jaffery Academy Mombasa", county: "Mombasa", type: "N/A" },
          { name: "Light International School Mombasa", county: "Mombasa", type: "N/A" },
          { name: "MM Shah International", county: "Mombasa", type: "N/A" },
          { name: "PCEA Makupa", county: "Mombasa", type: "N/A" },
          { name: "Shimo La Tewa", county: "Mombasa", type: "N/A" },
          { name: "Shimo La Tewa High School", county: "Mombasa", type: "N/A" },
          { name: "Shree Swaminarayan Academy", county: "Mombasa", type: "N/A" },
          { name: "SOS Hermann School", county: "Mombasa", type: "N/A" },
          { name: "Star of the Sea High School", county: "Mombasa", type: "N/A" },
          { name: "Brighter Days Academy", county: "Taita-Taveta", type: "N/A" },
          { name: "Bura Girls High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Dr. Aggrey High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Dr. Aggrey Secondary School", county: "Taita-Taveta", type: "N/A" },
          { name: "Eldoro Girls High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Kenyatta High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Mghalu High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Murray Girls", county: "Taita-Taveta", type: "N/A" },
          { name: "Mwangea Boys High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Mwasere", county: "Taita-Taveta", type: "N/A" },
          { name: "Ngami Secondary School", county: "Taita-Taveta", type: "N/A" },
          { name: "Our Lady of Perpetual Girls Secondary School", county: "Taita-Taveta", type: "N/A" },
          { name: "Senior Chief Mwangea Girls", county: "Taita-Taveta", type: "N/A" },
          { name: "St. Mary's Lushangonyi Boys", county: "Taita-Taveta", type: "N/A" },
          { name: "Timbila Boys High School", county: "Taita-Taveta", type: "N/A" },
          { name: "Hola Boys School", county: "Tana River", type: "N/A" },
          { name: "Oda Secondary School", county: "Tana River", type: "N/A" },
        ],
        "Nyanza Region": [
          { name: "St. Antoine Dago Kokore Secondary", county: "Kisumu", type: "N/A" },
          { name: "Nyakach Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "St. Alberts Ulanda Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "St. Bakhita's Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Emusire High School", county: "Kisumu", type: "N/A" },
          { name: "The Salvation Army Mudavadi Girls - Madzu", county: "Kisumu", type: "N/A" },
          { name: "St. Peter's Mumias High School", county: "Kisumu", type: "N/A" },
          { name: "Wareng' High School", county: "Kisumu", type: "N/A" },
          { name: "Maseno School", county: "Kisumu", type: "N/A" },
          { name: "Kisumu Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Mbita High School", county: "Kisumu", type: "N/A" },
          { name: "Mudasa Academy", county: "Kisumu", type: "N/A" },
          { name: "Wonderland Academy", county: "Kisumu", type: "N/A" },
          { name: "Sigoti Complex Girls' Secondary School", county: "Kisumu", type: "N/A" },
          { name: "St. Theresa's Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "St. Barnabas Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Lions High School Kisumu", county: "Kisumu", type: "N/A" },
          { name: "Xaverian Secondary School", county: "Kisumu", type: "N/A" },
          { name: "Kisumu Day", county: "Kisumu", type: "N/A" },
          { name: "Kisumu Boys' High School", county: "Kisumu", type: "N/A" },
          { name: "Joel Omino Secondary School", county: "Kisumu", type: "N/A" },
          { name: "ACK Rae Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Jalaram Academy", county: "Kisumu", type: "N/A" },
          { name: "Ahero Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Bishop Okoth Ojalla Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Kisumu Polytechnic", county: "Kisumu", type: "N/A" },
          { name: "St. Gregory Koru Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Achego Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Aga Khan Primary School", county: "Kisumu", type: "N/A" },
          { name: "Braeburn School", county: "Kisumu", type: "N/A" },
          { name: "Kisumu Academy", county: "Kisumu", type: "N/A" },
          { name: "Sinyolo Girls' High School", county: "Kisumu", type: "N/A" },
          { name: "Obwolo Secondary School", county: "Kisumu", type: "N/A" },
          { name: "Agai Mixed Secondary School", county: "Kisumu", type: "N/A" },
          { name: "St. Joseph's Barding High School", county: "Kisumu", type: "N/A" },
          { name: "St. Francis Rang'ala Girls", county: "Siaya", type: "N/A" },
          { name: "Ngiya Girls' High School", county: "Siaya", type: "N/A" },
          { name: "Maranda High School", county: "Siaya", type: "N/A" },
          { name: "Ramba Boys'", county: "Siaya", type: "N/A" },
          { name: "Sirembe Secondary School", county: "Siaya", type: "N/A" },
          { name: "Aluor Girls' Secondary School", county: "Siaya", type: "N/A" },
          { name: "Bishop Okoth's Girls' Mbaga", county: "Siaya", type: "N/A" },
          { name: "St. Augustine Nyamonye Girls'", county: "Siaya", type: "N/A" },
          { name: "Sinaga Girls' High School", county: "Siaya", type: "N/A" },
          { name: "Sawagongo Boys'", county: "Siaya", type: "N/A" },
          { name: "Maranda JSS", county: "Siaya", type: "N/A" },
          { name: "St. Mary's School Yala High School", county: "Siaya", type: "N/A" },
          { name: "Chianda High School", county: "Siaya", type: "N/A" },
          { name: "Rang'ala Girls High School", county: "Siaya", type: "N/A" },
          { name: "JOOUST/Alliance", county: "Siaya", type: "N/A" },
          { name: "Ambira Boys' High School", county: "Siaya", type: "N/A" },
          { name: "Sega Girls'", county: "Siaya", type: "N/A" },
          { name: "Mbaga Girls'", county: "Siaya", type: "N/A" },
          { name: "Lieta High School", county: "Siaya", type: "N/A" },
          { name: "Ratanga Boys'", county: "Homabay", type: "N/A" },
          { name: "Mbita High School", county: "Homabay", type: "N/A" },
          { name: "Agoro Sare High School", county: "Homabay", type: "N/A" },
          { name: "Asumbi Girls' High School", county: "Homabay", type: "N/A" },
          { name: "Mawengo Girls' Secondary School", county: "Homabay", type: "N/A" },
          { name: "Ulanda Girls' High School", county: "Migori", type: "N/A" },
          { name: "Nyabisawa Girls' High School", county: "Migori", type: "N/A" },
          { name: "Wasio Secondary School", county: "Migori", type: "N/A" },
          { name: "St. Joseph's School Rapogi", county: "Migori", type: "N/A" },
          { name: "St. Mary's Mabera Girls' Secondary School", county: "Migori", type: "N/A" },
          { name: "Nyabokarange Mixed Secondary School", county: "Migori", type: "N/A" },
          { name: "Lakehood School", county: "Migori", type: "N/A" },
          { name: "Oyugi Ogango Girls Secondary School", county: "Migori", type: "N/A" },
          { name: "Moi Gesusu High School", county: "Kisii", type: "N/A" },
          { name: "St. Vincent De Paul Nyanko", county: "Kisii", type: "N/A" },
          { name: "Kisii School", county: "Kisii", type: "N/A" },
          { name: "Riokindo Boys' High School", county: "Kisii", type: "N/A" },
          { name: "St. Theresa's Nyangusu Girls'", county: "Kisii", type: "N/A" },
          { name: "Nyabururu Girls'", county: "Kisii", type: "N/A" },
          { name: "Cardinal Otunga - Mosocho", county: "Kisii", type: "N/A" },
          { name: "Kaizen Schools", county: "Kisii", type: "N/A" },
          { name: "Senior Chief Musa Nyandusi Kegati High School", county: "Kisii", type: "N/A" },
          { name: "Fairmont International School", county: "Kisii", type: "N/A" },
          { name: "Kereri Girls Secondary School", county: "Kisii", type: "N/A" },
          { name: "Daraja Mbili Mixed Secondary School", county: "Kisii", type: "N/A" },
          { name: "Nyanchwa Girls High School", county: "Kisii", type: "N/A" },
          { name: "Timiza Academy", county: "Kisii", type: "N/A" },
          { name: "Sister Michaela Girls Secondary School", county: "Kisii", type: "N/A" },
          { name: "Nyaikuro Mixed Secondary School", county: "Nyamira", type: "N/A" },
          { name: "Nyambaria High School", county: "Nyamira", type: "N/A" },
          { name: "St. Pauls Gekano Boys' High School", county: "Nyamira", type: "N/A" },
          { name: "Nyasabakwa Boys' High School", county: "Nyamira", type: "N/A" },
          { name: "Sironga Girls High School", county: "Nyamira", type: "N/A" },
          { name: "Nyamiranga Secondary School", county: "Nyamira", type: "N/A" },
        ],
        "Western Region": [
          { name: "Friends School Lungai", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Tabani Friends", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Chebosi S.A. Boys", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "St. Luke's Boys Kimilili", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "S.H. Wamalwa Kijana", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Friends School Kamusinga", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Bukhaywa Secondary", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Moi Girls Kamusinga", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Lugulu Girls", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "St. Teresa's Kabula", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Kibabii Boys", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Chwele Girls", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Cardinal Otunga Girls", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Maliki Boys", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "St. Patricks Kimkung'i", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Chesamisi", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Bungoma High School", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Kibisi Friends", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Namachanja High", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Teremi Boys", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "St. Josephs Kamusinde", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "St. Mary's Sosio", county: "Bungoma", type: translations[language].schoolTypeOne },
          { name: "Sigalame Boys", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "Mundika", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "St. Monica Chakol Girls", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "Kisoko Girls", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "S.A. Kolanya Girls", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "Fr. Okudoi Secondary", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "Busianda Girls", county: "Busia", type: translations[language].schoolTypeOne },
          { name: "Lirhanda Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Sacred Heart Mukumu Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Butere Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Glory Value School", county: "Kakamega", type: translations[language].schoolTypeThree},
          { name: "Mabole Boys", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Friends Lwanda", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Agnes Girls Shibuye", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Mary's Mumias Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Kakamega School", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Shikunga School", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Mary's Mukumu JSS Girls", county: "Kakamega", type: translations[language].schoolTypeFour },
          { name: "St. Anne's Nzoia Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Anne Musoli Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Moi Girls Nangili Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Teresa's Eregi Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Bishop Sulumeti L.Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Archbishop Njenga", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Lubinu High School", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Makunda Muslim Secondary", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Loreto Matunda", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "St. Kizito Lusumu", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Shabwali Secondary", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Bulimbo Girls", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Mwihila Boys", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Matunda S.A.", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Kivaywa High", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Lugala S.C.", county: "Kakamega", type: translations[language].schoolTypeOne },
          { name: "Friends School Keveye", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Friends School Tigoi Girls", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Vihiga Friends School Boys", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Senende High School", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Nyang'ori Boys", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Ebwale Secondary", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Ebuyalu Secondary", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Kaimosi Girls", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Emmukunzi Secondary", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Keveye Girls", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Bunyore Girls", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Kilingili", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Moi Vokoli", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Eukhaya Secondary", county: "Vihiga", type: translations[language].schoolTypeOne },
          { name: "Kaimosi Girls", county: "Vihiga", type: translations[language].schoolTypeOne },
        ],
        "Rift Valley North": [
          { name: "Loreto High Matunda", county: "N/A", type: "N/A" },
          { name: "Bikeke Girls", county: "N/A", type: "N/A" },
          { name: "Kipsangul Boys", county: "N/A", type: "N/A" },
          { name: "Wareng High School", county: "N/A", type: "N/A" },
          { name: "St. Brigids Girls Kiminini", county: "N/A", type: "N/A" },
          { name: "Chewoyet High School", county: "N/A", type: "N/A" },
          { name: "St. Josephs Girls Kitale", county: "N/A", type: "N/A" },
          { name: "St. Josephs Boys Kitale", county: "N/A", type: "N/A" },
          { name: "St. Anthonys Boys Kitale", county: "N/A", type: "N/A" },
          { name: "Hill School Eldoret", county: "N/A", type: "N/A" },
          { name: "Arnessens Boys High School", county: "N/A", type: "N/A" },
          { name: "Kapsabet Boys", county: "N/A", type: "N/A" },
          { name: "Kapkoiga Girls", county: "N/A", type: "N/A" },
          { name: "Nasokol Girls", county: "N/A", type: "N/A" },
          { name: "St. Marks Cherangany", county: "N/A", type: "N/A" },
          { name: "Alliance Francaise Eldoret", county: "N/A", type: "N/A" },
          { name: "Metkei Girls", county: "N/A", type: "N/A" },
          { name: "St. Annes Academy Kiminini", county: "N/A", type: "N/A" },
          { name: "Racecourse Secondary School", county: "N/A", type: "N/A" },
          { name: "Sun And Shield School", county: "N/A", type: "N/A" },
          { name: "Turbo School", county: "N/A", type: "N/A" },
          { name: "Kamagut High School", county: "N/A", type: "N/A" },
          { name: "Ortum Boys High School", county: "N/A", type: "N/A" },
          { name: "Baringo Boys", county: "N/A", type: "N/A" },
          { name: "Lodwar Boys", county: "N/A", type: "N/A" },
          { name: "Kapropita Girls High School", county: "N/A", type: "N/A" },
          { name: "Samoei Boys", county: "N/A", type: "N/A" },
          { name: "Kapsabet Girls", county: "N/A", type: "N/A" },
          { name: "Kwanza Girls", county: "N/A", type: "N/A" },
          { name: "Uasin Gishu High", county: "N/A", type: "N/A" },
          { name: "Hill School Jss", county: "N/A", type: "N/A" },
          { name: "Chebisas Boys", county: "N/A", type: "N/A" },
          { name: "Moi Girls Eldoret", county: "N/A", type: "N/A" },
          { name: "Tartar Girls", county: "N/A", type: "N/A" },
          { name: "Nasokol Girls", county: "N/A", type: "N/A" },
          { name: "St. Elizabeth Murpus", county: "N/A", type: "N/A" },
          { name: "Kosirai Girls", county: "N/A", type: "N/A" },
          { name: "Ngeria Girls", county: "N/A", type: "N/A" },
          { name: "St. Elizabeth Girls", county: "N/A", type: "N/A" },
          { name: "Taito KTGA Secondary School", county: "N/A", type: "N/A" },
        ],
        "Rift Valley South": [
          { name: "Kaplong Boys High School", county: "Bomet", type: "N/A" },
          { name: "Tenwek High School", county: "Bomet", type: "N/A" },
          { name: "Lorna Laboso Girls High School", county: "Bomet", type: "N/A" },
          { name: "Empakasi Secondary School", county: "Kajiado", type: "N/A" },
          { name: "Nkaimurunya Secondary School", county: "Kajiado", type: "N/A" },
          { name: "Kaputiei Secondary", county: "Kajiado", type: "N/A" },
          { name: "Merishaw School", county: "Kajiado", type: "N/A" },
          { name: "Korongoi Girls' High School", county: "Kericho", type: "N/A" },
          { name: "Kericho High School", county: "Kericho", type: "N/A" },
          { name: "Kipsigis Girls High School", county: "Kericho", type: "N/A" },
          { name: "Samoei Boys Secondary School", county: "Kericho", type: "N/A" },
          { name: "Nanyuki School", county: "Laikipia", type: "N/A" },
          { name: "Naivasha Girls Secondary", county: "Nakuru", type: "N/A" },
          { name: "Vanessa Grant Girls' School", county: "Nakuru", type: "N/A" },
          { name: "Utumishi Girls Academy", county: "Nakuru", type: "N/A" },
          { name: "Nakuru Girls High School", county: "Nakuru", type: "N/A" },
          { name: "Moi Secondary, Nakuru", county: "Nakuru", type: "N/A" },
          { name: "Kingdom International Premier Schools", county: "Nakuru", type: "N/A" },
          { name: "Sun And Shield", county: "Nakuru", type: "N/A" },
          { name: "Njoro Girls High School", county: "Nakuru", type: "N/A" },
          { name: "Nakuru High School", county: "Nakuru", type: "N/A" },
          { name: "Gitare Secondary School", county: "Nakuru", type: "N/A" },
          { name: "Milimani High School", county: "Nakuru", type: "N/A" },
          { name: "Utumishi Boys' Academy", county: "Nakuru", type: "N/A" },
          { name: "Menengai High", county: "Nakuru", type: "N/A" },
          { name: "Maralal High School", county: "Samburu", type: "N/A" },
          { name: "Kisima Girls High School", county: "Samburu", type: "N/A" },
        ],
        "Nairobi Region": [
          { name: "Bakhita Primary/JSS/OLVTC - South B", county: "Nairobi", type: "N/A" },
          { name: "Nairobi Jaffery Academy - Lavington", county: "Nairobi", type: "N/A" },
          { name: "Embakasi Girls Secondary School", county: "Nairobi", type: "N/A" },
          { name: "Logos Christian School Nairobi", county: "Nairobi", type: "N/A" },
          { name: "Huruma Girls High School", county: "Nairobi", type: "N/A" },
          { name: "Upper Hill School", county: "Nairobi", type: "N/A" },
          { name: "Regis Runda Academy", county: "Nairobi", type: "N/A" },
          { name: "Valence School / Kids Zone Educational Centre/ Jabali Preparatory", county: "Nairobi", type: "N/A" },
          { name: "Damacrest Senior School", county: "Nairobi", type: "N/A" },
          { name: "Home School/Private Lessons", county: "Nairobi", type: "N/A" },
          { name: "Kibera Secondary", county: "Nairobi", type: "N/A" },
          { name: "G.S.U Kimbo Primary School", county: "Nairobi", type: "N/A" },
          { name: "Royal Brains School, G.S.U Kimbo", county: "Nairobi", type: "N/A" },
          { name: "Buruburu Girls Secondary School", county: "Nairobi", type: "N/A" },
          { name: "St. Bakhita Eagle", county: "Nairobi", type: "N/A" },
          { name: "Shepherds Junior School", county: "Nairobi", type: "N/A" },
          { name: "Ruai Girls Secondary", county: "Nairobi", type: "N/A" },
          { name: "Mary Leakey Girls' High School", county: "Nairobi", type: "N/A" },
          { name: "Kianda School", county: "Nairobi", type: "N/A" },
          { name: "State House Girls", county: "Nairobi", type: "N/A" },
          { name: "Jabali Preparatory School", county: "Nairobi", type: "N/A" },
          { name: "St Faustina/Gemstar Amani School", county: "Nairobi", type: "N/A" },
          { name: "Aquinas High School", county: "Nairobi", type: "N/A" },
          { name: "St. Ruth", county: "Nairobi", type: "N/A" },
          { name: "St. Christopher's School", county: "Nairobi", type: "N/A" },
          { name: "St. George's Girls Nairobi", county: "Nairobi", type: "N/A" },
          { name: "Lenana School", county: "Nairobi", type: "N/A" },
          { name: "Brightstar Academy", county: "Nairobi", type: "N/A" },
          { name: "PCEA Langata School", county: "Nairobi", type: "N/A" },
          { name: "Gacio Junior Academy", county: "Nairobi", type: "N/A" },
          { name: "Blue Hills Academy - Ruai", county: "Nairobi", type: "N/A" },
          { name: "Riara Springs Girls' High School", county: "Nairobi", type: "N/A" },
          { name: "CITAM Schools - Woodley", county: "Nairobi", type: "N/A" },
          { name: "Queen Esther Ville Complex", county: "Nairobi", type: "N/A" },
          { name: "Carta Oakhill School", county: "Nairobi", type: "N/A" },
          { name: "Jamhuri High School", county: "Nairobi", type: "N/A" },
          { name: "Ruthimitu Mixed Secondary School", county: "Nairobi", type: "N/A" },
          { name: "State House Girls High School", county: "Nairobi", type: "N/A" },
          { name: "Starlight Senior Education Centre", county: "Nairobi", type: "N/A" },
          { name: "Mountain View School", county: "Nairobi", type: "N/A" },
          { name: "L'Alliance Française de Nairobi", county: "Nairobi", type: "N/A" },
          { name: "Foresight Academy", county: "Nairobi", type: "N/A" },
          { name: "The Riara International School", county: "Nairobi", type: "N/A" },
          { name: "Starehe Girls' Centre & School", county: "Nairobi", type: "N/A" },
          { name: "P.C.E.A Dr. Ngoima Memorial School", county: "Nairobi", type: "N/A" },
          { name: "Braeside Lavington", county: "Nairobi", type: "N/A" },
        ],
        "South Eastern Region": [
          { name: "St Mary's Ukia Girls", county: "N/A", type: "N/A" },
          { name: "Mukaa Girls", county: "N/A", type: "N/A" },
          { name: "Good Shepherd Makueni", county: "N/A", type: "N/A" },
          { name: "Makueni Girls", county: "N/A", type: "N/A" },
          { name: "Kitui School", county: "N/A", type: "N/A" },
          { name: "Mwaani Boys", county: "N/A", type: "N/A" },
          { name: "Kathonzweni Boys", county: "N/A", type: "N/A" },
          { name: "St. Angela's Kitui", county: "N/A", type: "N/A" },
          { name: "Machakos Girls", county: "N/A", type: "N/A" },
          { name: "Mulala Girls", county: "N/A", type: "N/A" },
          { name: "Muthetheni Girls", county: "N/A", type: "N/A" },
          { name: "Kathiani Boys", county: "N/A", type: "N/A" },
          { name: "St.Francis Misyani Girls", county: "N/A", type: "N/A" },
          { name: "St. Charles Lwanga Kitui", county: "N/A", type: "N/A" },
          { name: "ABC Mitaboni Girls", county: "N/A", type: "N/A" },
          { name: "Machakos School", county: "N/A", type: "N/A" },
          { name: "Muthale girls", county: "N/A", type: "N/A" },
          { name: "Mbooni Boys", county: "N/A", type: "N/A" }, 
        ],
        "Primary/Private": [
          { name: "Makini School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Fr Joseph Richetti School", county: "Banana", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "The Wango Christian Academy", county: "Bondo", type: translations[language].schoolTypeSeven },
          { name: "Dreams Hills School", county: "Eldamaravine", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Lions School Eldoret", county: "Eldoret", type: translations[language].schoolTypeSeven },
          { name: "Light International School", county: "Jamhuri", type: translations[language].schoolTypeSeven },
          { name: "Juja St. Peters School", county: "Juja", type: translations[language].schoolTypeSeven },
          { name: "Acacia Digital Academy", county: "Kajiado", type: translations[language].schoolTypeSix },
          { name: "Cours à Domicile", county: "Kajiado", type: translations[language].schoolTypeSeven },
          { name: "Prime Junior School", county: "Kajiado", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Precious Gems School-Rongai", county: "Kajiado", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Ngong Huruma Schools", county: "Kajiado", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Kericho Primary and Junior Secondary School", county: "Kericho", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Wisdom Pot School", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "Pink Rose", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "St. Antony's School", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "GrandStart", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "GSU Kimbo Primary School/Grace School", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "St. George School", county: "Kiambu", type: translations[language].schoolTypeSeven },
          { name: "Jabali Preparatory", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "North Riara Ridge Academy", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Creative Integrated School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Queen Esther Ville Complex", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Kids Zone Educational Center", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Lily of the Valley Junior School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Valence School, Kids Zone, Baseline Academy, Jabali Preparatory", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Foresight Academy", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Gacio Primary School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Juja St Peter's School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Kabunge School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Foresight Academy - Limuru Road", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Damacrest School", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}, ${translations[language].schoolTypeEight}` },
          { name: "Naisula School - Tiilisi", county: "Kiambu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeEight}` },
          { name: "Excel Elementary School", county: "Kisii", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Fairmonts International School", county: "Kisii", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Aga Khan Primary School Kisumu", county: "Kisumu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Citam School Kisumu", county: "Kisumu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Jalaram Academy", county: "Kisumu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Kisumu Academy", county: "Kisumu", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeEight}` },
          { name: "Maasai Corner Academy", county: "Kwale", type: translations[language].schoolTypeSeven },
          { name: "Lukenya Schools", county: "Machakos", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Machakos Academy", county: "Machakos", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeEight}` },
          { name: "Mmshah", county: "Mombasa", type: translations[language].schoolTypeSeven },
          { name: "Shree Swaminarayan Academy", county: "Mombasa", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeEight}` },
          { name: "Braeburn Mombasa", county: "Mombasa", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeEight}, Alliance française` },
          { name: "Consolata School Kiambu", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "The King's School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Nairobi Waldorf School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "CornerStone Primary", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Braeside Lavington", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Shani Angaza School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Fortune House School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Kinderworld Academy South C", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "LaSalle Catholic School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Premese Junior Academy", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "The Banda School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Briar Rose Junior School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Agha Khan Primary (Nairobi), Hekima School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "CITAM Schools Woodley", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Parklands Baptist School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "Strathmore School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "Potterhouse School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "SABIS International School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "Brookshine Schools", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "Little Wonder School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "PCEA Langata School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Makini Schools", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Consolata Primary School Kiambu", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Indian Primary School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Melisah", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "St Mary's Sportsview Academy", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Joytech Learning Centre", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "Faulu School", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "St Austin's Academy", county: "Nairobi", type: translations[language].schoolTypeSeven },
          { name: "St Mary's School Nairobi", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Braeburn Garden Estate", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}, ${translations[language].schoolTypeFive}` },
          { name: "St. Scholastica Catholic School", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Tender Care Junior Academy", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Consolata School Westlands", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Samaj School Nairobi", county: "Nairobi", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFive}` },
          { name: "Parler Center, Mount Longonot School", county: "Naivasha", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Maai Mahiu Sacred Heart School", county: "Naivasha", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Zuri School", county: "Nakuru", type: translations[language].schoolTypeSeven },
          { name: "Little Friends Junior Academy", county: "Nakuru", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Kitengela International Schools", county: "Nakuru", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
          { name: "Zuri School", county: "Nakuru", type: translations[language].schoolTypeSeven },
          { name: "Lizar School Naivasha", county: "Nakuru", type: translations[language].schoolTypeSeven },
          { name: "Kamagut School", county: "Rift Valley", type: translations[language].schoolTypeSeven },
          { name: "Twiga Plains School", county: "Tala", type: translations[language].schoolTypeSeven },
          { name: "P.C.E.A Dr Ngoima Memorial School", county: "Thika", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeFour}` },
          { name: "Carta Oak Hill School", county: "Thika", type: translations[language].schoolTypeSeven },
          { name: "Morning Star Primary School", county: "Turkana", type: translations[language].schoolTypeSeven },
          { name: "French for Peace and Human Rights Project/Morning Star Primary School", county: "Turkana", type: translations[language].schoolTypeSeven },
          { name: "Moses Mudavadi School, Mululu", county: "Western", type: translations[language].schoolTypeSeven },
          { name: "Luanda Happy Day Academy", county: "Western", type: `${translations[language].schoolTypeSeven}, ${translations[language].schoolTypeSix}` },
        ],
        "Teacher Training College": [
          { name: "Murang'a TTC", county: "N/A", type: "N/A" },
          { name: "Shanzu TTC", county: "N/A", type: "N/A" },
          { name: "Migori TTC", county: "N/A", type: "N/A" },
          { name: "Eregi TTC", county: "N/A", type: "N/A" },
          { name: "Kamwenje TTC", county: "N/A", type: "N/A" },
          { name: "Kagumo TTC", county: "N/A", type: "N/A" },
          { name: "Aberdares TTC", county: "N/A", type: "N/A" },
          { name: "Machakos TTC", county: "N/A", type: "N/A" },
          { name: "Kericho TTC", county: "N/A", type: "N/A" },
          { name: "Asumbi TTC", county: "N/A", type: "N/A" },
          { name: "Kibabii TTC", county: "N/A", type: "N/A" },
          { name: "Kaimosi TTC", county: "N/A", type: "N/A" },
          { name: "Taita Taveta TTC", county: "N/A", type: "N/A" },
          { name: "Bunyore TTC", county: "N/A", type: "N/A" },
          { name: "Seme TTC", county: "N/A", type: "N/A" },
          { name: "Narok TTC", county: "N/A", type: "N/A" },
          { name: "Tambach TTC", county: "N/A", type: "N/A" },
          { name: "Thogoto TTC", county: "N/A", type: "N/A" },
          { name: "Igoji TTC", county: "N/A", type: "N/A" },
        ],
        "TVET/Polytechnic": [
          { name: "PC Kinyanjui TTI", county: "N/A", type: "N/A" },
          { name: "Taita Taveta National Polytechnic", county: "N/A", type: "N/A" },
          { name: "RTI & KIFLAPS", county: "N/A", type: "N/A" },
          { name: "Kitale National Polytechnic", county: "N/A", type: "N/A" },
          { name: "Laikipia North TVC", county: "N/A", type: "N/A" },
          { name: "Kamukunji Technical & Vocational College", county: "N/A", type: "N/A" },
          { name: "Masai National Polytechnic", county: "N/A", type: "N/A" },
          { name: "The Rift Valley National Polytechnic", county: "N/A", type: "N/A" },
          { name: "Nyeri Polytechnic", county: "N/A", type: "N/A" },
          { name: "Meru National Polytechnic", county: "N/A", type: "N/A" },
          { name: "Wote TTI", county: "N/A", type: "N/A" },
          { name: "MIBS College", county: "N/A", type: "N/A" },
          { name: "Kisumu Polytechnic", county: "N/A", type: "N/A" },
          { name: "Wildlife Research & Training Institute, Naivasha", county: "N/A", type: "N/A" },
          { name: "Our Lady of Mercy Vocational Training Institute", county: "N/A", type: "N/A" },
        ],   
      };

    const handleRegionClick = (region) => {
        setSelectedRegion(region);
    };

    return (
      <>
        <div className="schools-region-intro-bg">

        </div>
        <div className="schools-region-container">
          <div className="schools-region-intro">
            <h2>{translations[language].schoolsByRegionHeading}</h2>
          </div>
          <div className="schools-region-buttons">
            {statsData.map((region, index) => (
              <button
                key={index}
                onClick={() => handleRegionClick(region.regionEn)}
                className={selectedRegion === region.regionEn ? "active" : ""}
              >
                {language === "en" ? region.regionEn : region.regionFr}
              </button>
            ))}
          </div>

          {/* Display schools in a table for the selected region */}
          {selectedRegion && (
            <div className="region-schools-table">
              <h3>
                {language === "en" ? selectedRegion : statsData.find(r => r.regionEn === selectedRegion)?.regionFr}
              </h3>

              <table border='3' cellPadding='15' cellSpacing='1'>
                <thead>
                  <tr>
                    <th>{translations[language].schoolNameHeading}</th>
                    <th>{translations[language].countyHeading}</th>
                    <th>{translations[language].schoolTypeHeading}</th>
                  </tr>
                </thead>
                <tbody>
                  {schoolData[selectedRegion]?.map((school, index) => (
                    <tr key={index}>
                      <td>{school.name}</td>
                      <td>{school.county}</td>
                      <td>{school.type}</td>
                    </tr>
                  )) || <tr><td colSpan="3">{translations[language].schoolNoDataText}</td></tr>}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </>
  );
};

export default SchoolsByRegion;
