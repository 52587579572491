import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import Header from "./components/Header";
import IntroductionSection from "./components/IntroductionSection";
import AboutBrief from "./components/AboutBrief";
import AchievementsOverview from "./components/AchievementsOverview";
import MembershipIntro from "./components/MembershipIntro";
import PartnerRecognitionSection from "./components/PartnerRecognitionSection";
import EventsOverview from "./components/EventsOverview";
import Publications from "./components/Publications";
import ExecutiveMembers from "./components/ExecutiveMembers";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import Events from "./components/Events";
import "./App.css";
import Contact from "./components/Contact";
import UpcomingEvents from "./components/events/UpcomingEvents";
import PastEvents from "./components/events/PastEvents";
import Resources from "./components/Resources";
import Membership from "./components/Membership";
import News from "./components/News";
import Gallery from "./components/Gallery";
import FAQs from "./components/FAQs";
import { LanguageContext } from "./context/LanguageContext";
import { translations } from "./data/translationsData";
import CallToAction from "./components/CallToAction";
import EventDetails from "./components/EventDetails";
import NewsDetail from "./components/NewsDetail";
import Francophonie from "./components/Francophonie";
import SchoolStats from "./components/SchoolStats";
import SchoolsByRegion from "./components/SchoolsByRegion";

ReactGA.initialize("G-MSSM7T92YL");

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const { language } = useContext(LanguageContext);

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 3800);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Helmet>
          <title>{translations[language].home} | KATF</title>
          <meta name="keywords" content="French, Association of Teachers of French" />
          <meta
            name="description"
            content={translations[language].introductionText} 
          />
          <meta property="og:title" content={translations[language].home + " | KATF"}  />
          <meta
            property="og:description"
            content={translations[language].introductionText}
          />
          <meta property="og:image" content="/assets/webi_sg_des.webp" />
          <meta property="og:url" content="https://katf.co.ke" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={translations[language].home + " | KATF"}  />
          <meta
            name="twitter:description"
            content={translations[language].introductionText}
          />
          <meta name="twitter:image" content="/assets/katf_logo.png" />
          <meta name="twitter:card" content="/assets/katf_logo.png" />
        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/events/*" element={<EventsRoutes />} />
          <Route path="/events/:eventId" element={<EventDetails />} />
          <Route path="/resources/" element={<Resources />} />
          <Route path="/membership/" element={<Membership />} />
          <Route path="/news/" element={<News />} />
          <Route path="/news/:newsId" element={<NewsDetail />} />
          <Route path="/contact-us/" element={<Contact />} />
          <Route path="/gallery/" element={<Gallery />} />
          <Route path="/faqs/" element={<FAQs />} />
          <Route path="/executive" element={<ExecutiveMembers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/francophonie-au-kenya" element={<Francophonie />} />
          <Route path="/schools-by-region" element={<SchoolsByRegion />} />
        </Routes>
        {showScrollToTop && (
          <div className='scroll-to-top'>
            <button onClick={scrollToTop}>
              <i className="bx bxs-up-arrow-circle"></i>
            </button>
          </div>
        )}
        <Footer />
      </div>
    </Router>
  );
};

const Home = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home | KATF",
    });
  });

  return (
    <>
      <IntroductionSection />
      <AboutBrief />
      <AchievementsOverview />
      <MembershipIntro />
      <PartnerRecognitionSection />
      <SchoolStats />
      <EventsOverview />
      <Publications />
      <CallToAction />
    </>
  );
};

const EventsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Events />} />
      <Route path="/upcoming-events" element={<UpcomingEvents />} />
      <Route path="/past-events" element={<PastEvents />} />
    </Routes>
  );
};

export default App;

/**RELEASE (Version 0.1.2)**/
