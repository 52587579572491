import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { translations } from '../data/translationsData';
import { LanguageContext } from '../context/LanguageContext';

const NewsDetail = () => {
  const { language } = useContext(LanguageContext);
  const { newsId } = useParams(); // newsId will come from the URL

  const sampleNews = {
    Events: [
      {
        id: 'kicd-meeting',
        title: translations[language].articleOneTitle,
        date: translations[language].articleOneDate,
        content: translations[language].articleOneContent,
      },
      {
        id: 'resource-centre-opening',
        title: translations[language].articleTwoTitle,
        date: translations[language].articleTwoDate,
        content: translations[language].articleTwoContent,
      },
      {
        id: 'french-teachers-day',
        title: translations[language].articleSevenTitle,
        date: translations[language].frenchTeachersDayDateRange,
        content: translations[language].frenchTeachersDayDescription,
      }
    ],
    'Press Releases': [
      {
        id: 'katf-web-conference',
        title: translations[language].articleThreeTitle,
        date: translations[language].articleThreeDate,
        content: translations[language].articleThreeContent,
      },
      {
        id: 'katf-elections',
        title: translations[language].articleFourTitle,
        date: translations[language].articleFourDate,
        content: translations[language].electionsDescription,
      }
    ],
    'Random Articles': [
      {
        id: 'french-language-popularity',
        title: translations[language].articleFiveTitle,
        date: translations[language].articleFiveDate,
        content: translations[language].articleFiveContent,
      },
      {
        id: 'teacher-training-expansion',
        title: translations[language].articleSixTitle,
        date: translations[language].articleSixDate,
        content: translations[language].articleSixContent,
      }
    ]
  };

  // Combine all categories into one array to search for the article
  const allNews = [...sampleNews.Events, ...sampleNews['Press Releases'], ...sampleNews['Random Articles']];

  // Find the selected article by its ID
  const selectedArticle = allNews.find((article) => article.id === newsId);

  if (!selectedArticle) {
    return <div>Article not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{selectedArticle.title} | KATF</title>
      </Helmet>

      <div className="event-details news-detail">
        <h1>{selectedArticle.title}</h1>
        <h5>{selectedArticle.date}</h5>
        <p>{selectedArticle.content}</p>
      </div>
    </>
  );
};

export default NewsDetail;