import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { translations } from '../data/translationsData';
import { LanguageContext } from '../context/LanguageContext';

const Membership = () => {

    const { language } = useContext(LanguageContext);

    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: translations[language].membership,
        });
    });

    return(
        <>
            <Helmet>
                <title>{translations[language].membership} | KATF</title>
                <meta name="description" content={translations[language].membershipParagraph} />
                <meta property="og:title" content={translations[language].membership +  " | KATF"} />
                <meta property="og:description" content={translations[language].membershipParagraph} />
                <meta property="og:image" content="/assets/members_unite.webp" />
                <meta property="og:url" content="https://katf.co.ke/membership" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content={translations[language].membership +  " | KATF"} />
                <meta name="twitter:description" content={translations[language].membershipParagraph}/>
                <meta name="twitter:image" content="/assets/katf_logo.png" />
                <meta name="twitter:card" content="/assets/katf_logo.png" />
            </Helmet>

            <div className='membership-main'>
                <div className='membership-intro'>
                    
                </div>
                <div className='membership-details'>
                    <h1>{translations[language].membership}</h1>
                    <p>{translations[language].membershipParagraph}</p>
                    <div className='member-info'>
                        <div className='info-card' id='member_benefits'>
                            <h3>{translations[language].membershipBenefitsHeading}</h3>
                            <ul>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit1}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit2}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit3}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit4}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit5}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit6}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit7}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit8}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit9}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberBenefit10}</li>
                            </ul>
                        </div>
                        <div className='info-card' id='join_how'>
                            <h3>{translations[language].membershipHowToHeading}</h3>
                            <ul>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberHowStep1}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberHowStep2}</li>
                                <li><i class='bx bxs-bookmark-star'></i>{translations[language].memberHowStep3}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='payment-guide'>
                    <div className='payment-guide-heading'>
                        <h2>{translations[language].paymentGuideHeading}</h2>
                    </div>
                    <div className='payment-content'>
                        <div className='new-members'>
                            <h3>{translations[language].newMemberPaymentH1}</h3>
                            <h5>{translations[language].newMemberPaymentH2} : <span>Ksh. 300</span></h5>
                            <h5>{translations[language].newMemberPaymentH3} : <span>Ksh. 500</span></h5>
                            <h5>Total : <span>Ksh. 800</span></h5>
                        </div>
                        <div className='continuing-members'>
                            <h3>{translations[language].contMemberPaymentH1}</h3>
                            <h5>{translations[language].contMemberPaymentH2} : <span>Ksh. 500</span></h5>
                        </div>
                    </div>
                </div>
                <div className='treasurer-message'>
                    <h2>{translations[language].membershipCallHeading}</h2>
                    <img src='/assets/about_membership.jpeg' alt=''/>
                </div>
            </div>
        </>
    );

}

export default Membership;