import React, { useContext } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../context/LanguageContext';
import { translations } from '../data/translationsData';

const Footer = () => {

    const { language, toggleLanguage } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

      const linkText = event.target.textContent;
  
      ReactGA.event({
        category: 'User Interaction',
        action: 'Clicked A Btn Link',
        label: linkText,
      });
    };

  return (
    <footer>
        <div className="footer">
            <div className="footer-left">
                <div className="footer-logo">
                    <Link to="/">
                        <img src="/assets/katf_logo.png" alt="KATF logo" />
                    </Link>
                </div>
                <div className="footer-text">
                    <p>{translations[language].footerParagraphSt1}<br />{translations[language].footerParagraphSt2}<br />{translations[language].footerParagraphSt3}</p>
                </div>
            </div>
            <div className="footer-center">
                <div className="center-content">
                    <h4>{translations[language].footerQLHeading}</h4>
                    <ul>
                        <li><Link to="/">{translations[language].footerLinkItem1}</Link></li>
                        <li><Link to="/about-us">{translations[language].footerLinkItem2}</Link></li>
                        <li><Link to="/membership">{translations[language].footerLinkItem3}</Link></li>
                        <li><Link to="/events">{translations[language].footerLinkItem4}</Link></li>
                        <li><Link to="/gallery">{translations[language].footerLinkItem5}</Link></li>
                        <li><Link to="/resources">{translations[language].footerLinkItem6}</Link></li>
                        <li><Link to="/news">{translations[language].footerLinkItem7}</Link></li>
                    </ul>
                </div>
            </div>
            <div className='footer-right'>
                <div className='connect'>
                    <h4>{translations[language].footerConnectHeading}</h4>
                    <ul>
                        <li><Link to="mailto:info@katf.co.ke" onClick={handleLinkClick}>info@katf.co.ke<i className='bx bxs-envelope'></i></Link></li>
                        <li><Link to="tel:+254723474929" onClick={handleLinkClick}>+254723474929<i className='bx bx-phone-outgoing'></i></Link></li>
                        <li><Link to="https://www.google.com/maps/place/Nairobi/@-1.3028603,36.6825778,11z/data=!3m1!4b1!4m6!3m5!1s0x182f1172d84d49a7:0xf7cf0254b297924c!8m2!3d-1.2920659!4d36.8219462!16zL20vMDVkNDk?entry=ttu" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}>Nairobi, Kenya<i className='bx bx-buildings'></i></Link></li>
                    </ul>
                </div>
                <div className="footer-socials">
                    <Link to="https://www.facebook.com/profile.php?id=100067761818814" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-facebook'></i></Link>
                    <Link to="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-instagram'></i></Link>
                    <Link to="https://api.whatsapp.com/send?phone=254723474929&text=Hello%20KATF%20I%20got%20your%20WhatsApp%20information%20from%20your%20website.%20I%20want%20to%20inquire%20about..." target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-whatsapp'></i></Link>
                    <Link to="https://linkedin.com/company/associationkatf/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-linkedin'></i></Link>
                    <Link to="https://x.com/assoc_katf" target="_blank" rel="noopener noreferrer"><i className='bx bxl-twitter' onClick={handleLinkClick}></i></Link>
                </div>
            </div>
        </div>
        <div className='footer-lower'>
            <div className='copyright'>
                <p><i className='bx bx-copyright'></i>{translations[language].footerCopyright}</p>
            </div>
            <div className="language-toggle">
                <h6>{language === 'en' ? 'Switch To French' : 'Passer à L\'anglais'}</h6>
                <button onClick={() => toggleLanguage()}>{language === 'en' ? '🇫🇷' : '🇬🇧'}</button>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
